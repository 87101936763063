<template>
  <div class="index">
    <m-header ref="header" />
    <router-view />
    <m-tail />
  </div>
</template>

<script>
import MHeader from "./components/header/index.vue";
import MTail from "./components/tail/index.vue";
export default {
  components: {
    MHeader,
    MTail,
  },
};
</script>

<style>
.el-popover {
  min-width: 100px;
}
</style>
<style scoped lang="scss">
.index {
  overflow-x: hidden;
  background-color: white;
}
</style>
