<template>
  <div id="tail" class="tail">
    <div class="tail-top">
      <template v-if="localStorageType==1">
        <p class="tail-title">从这里开始 开启医疗新生态</p>
      </template>
      <template v-else-if="localStorageType==2">
        <p class="tail-title">从这里开始 构建企业应用</p>
      </template>
    </div>
    <div class="tail-bottom">
      <div class="tail-bottom-logo">
        <template v-if="localStorageType==1">
          <img class="logo" src="@/assets/kyyl.png" alt="" />
        </template>
        <template v-else-if="localStorageType==2">
          <img class="logo" src="@/assets/kyqy.png" alt="" />
        </template>
      </div>
      <div class="tail-bottom-qrcode">
        <img class="qrcode" src="@/assets/opencom-weixinGroup.png" alt=""/>
        <p>扫一扫联系我们</p>
      </div>
      <div class="tail-bottom-class">
        <p>友情链接</p>
        <p class="tail-bottom-class-p">天天数链 | 新致软件 </p>
      </div>
    </div>
    <div class="tail-record">
      Copyright©2023 武汉新致数字科技有限公司 保留所有权利 鄂ICP备2022011542号
      鄂公网安备 33011002015175号
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      localStorageType: localStorage.getItem("type") || 2,
    };
  },
};
</script>

<style scoped lang="scss">
.tail {
  &-top {
    height: 110px;
    background: #161616;
  }

  &-title {
    font-size: 24px;
    color: #ffffff;
    line-height: 35px;
    text-align: center;
    padding-top: 40px;
  }

  &-btn {
    text-align: center;
    margin-top: 24px;
  }

  &-bottom {
    height: 420px;
    background: #20232a;

    &-logo {
      text-align: center;
      padding-top: 60px;

      img {
        width: 160px;
      }
    }

    &-qrcode {
      text-align: center;
      margin-top: 40px;
      color: white;

      img {
        width: 160px;
      }
    }

    &-class {
      display: flex;
      justify-content: center;

      p {
        color: white;
      }

      &-p {
        color: #6d7278 !important;
        margin-left: 16px;
      }
    }
  }

  &-record {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    padding: 20px 0;
    background: #20232a;
    font-size: 17px;
    color: #797b7f;
    text-align: center;
  }
}
</style>
