<template>
  <div class="header">
    <div class="header-logo">
      <template v-if="localStorageType == 1">
        <img src="@/assets/kyyl.png" alt="开源医疗" />
      </template>
      <template v-else-if="localStorageType == 2">
        <img src="@/assets/kyqy.png" alt="开源企业" />
      </template>
      <el-dropdown trigger="click">
        <span class="el-dropdown-link">
          <el-icon class="el-icon-arrow-down" />
        </span>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item @click.native="onSelect(1)"
              >开源医疗</el-dropdown-item
            >
            <el-dropdown-item @click.native="onSelect(2)"
              >开源企业</el-dropdown-item
            >
            <!-- 判断本地localStorage.getItem("type")的值-->
            <!-- <template v-if="localStorageType==1">
              <el-dropdown-item>1</el-dropdown-item>
            </template>
            <template v-else-if="localStorageType==2">
              <el-dropdown-item>2</el-dropdown-item>
            </template> -->
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </div>
    <div class="header-op">
      <el-button v-if="!getUserInfo.userName" color="#333" @click="isShowLogin">
        <i style="font-size: 26px" class="el-icon-user"></i>
      </el-button>
      <el-dropdown v-if="getUserInfo && getUserInfo.userName">
        <span class="el-dropdown-link"> 欢迎, {{ getUserInfo.userName }} </span>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item @click.native="jumpPage('account')">
              <i class="iconfont-new icon-zhanghu" />账户中心
            </el-dropdown-item>
            <el-dropdown-item @click.native="openAdmin()">
              <i class="iconfont-new icon-zixun" />开源社区管理
            </el-dropdown-item>
            <el-dropdown-item>
              <el-button size="small" @click.stop="loginOut" type="danger">
                退出
              </el-button>
            </el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
      <el-dropdown trigger="click">
        <span class="el-dropdown-link">
          <el-icon style="font-size: 26px" class="el-icon-menu" />
        </span>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item>
              <el-button
                style="width: 120px"
                :type="$route.name === 'mobile-home' ? 'primary' : ''"
                @click="onClick('mobile-home')"
              >
                首页
              </el-button>
            </el-dropdown-item>
            <el-dropdown-item>
              <el-button style="width: 120px" @click="onAnchor('production')">
                产品与服务
              </el-button>
            </el-dropdown-item>
            <el-dropdown-item>
              <el-button style="width: 120px" @click="onAnchor('case')"
                >行业案例</el-button
              >
            </el-dropdown-item>
            <el-dropdown-item>
              <el-button
                style="width: 120px"
                :type="$route.name.includes('community') ? 'primary' : ''"
                @click="onClick('community')"
              >
                开源社区
              </el-button>
            </el-dropdown-item>
            <el-dropdown-item>
              <el-button style="width: 120px" @click="onAnchor('news')">
                行业资讯
              </el-button>
            </el-dropdown-item>
            <el-dropdown-item>
              <el-button style="width: 120px" @click="onAnchor('tail')">
                关于我们
              </el-button>
            </el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </div>
    <login-model ref="loginModel" @loginSuccess="updateUserInfo"></login-model>
  </div>
</template>

<script>
import LoginModel from "@/components/loginModel.vue";
import { mapGetters, mapMutations } from "vuex";
import { MessageBox } from "element-ui";
import { loginOut } from "@/api/login";
//import community from "@/pages/new/community/index.vue";

export default {
  components: { LoginModel },
  data() {
    return {
      localStorageType: localStorage.getItem("type") || 2,
    };
  },
  methods: {
    ...mapMutations("user", ["setToken", "updateUserInfo"]),

    onClick(name) {
      this.$router.push({
        name,
      });
    },

    isShowLogin() {
      this.$refs.loginModel.showModel();
      this.$store.commit("setIsShow", 1);
    },

    loginOut() {
      let redirectedFrom = this.$route.path;
      MessageBox.confirm("您是否要退出?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          loginOut().then((res) => {
            if (res.code === 200) {
              localStorage.clear();
              this.setToken("");
              this.updateUserInfo("");
              if (redirectedFrom.includes("/account")) {
                this.$router.push({ name: "home" });
              }

              this.$message({
                type: "success",
                message: "退出成功",
              });
            } else {
              this.$message.error("退出失败");
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "取消退出",
          });
        });
    },

    jumpPage(name) {
      this.$router.push({ name });
    },
    openAdmin() {
      window.open("http://opencom.com.cn:40008/");
    },

    onAnchor(id) {
      if (this.$route.name !== "mobile-home" && id !== "tail") {
        this.$router.push({
          path: `/mobile/new/home#${id}`,
        });
      } else {
        console.log(document.location, this.$route);
        document.location.href = `${document.location.origin}/#${this.$route.path}#${id}`;
      }
    },

    onSelect(item) {
      const type = localStorage.getItem("type") || 2;
      if (type !== item) {
        localStorage.setItem("type", item);
        window.location.reload();
      }
      console.log(item);
    },
  },

  computed: {
    ...mapGetters("user", ["getUserInfo"]),
  },
};
</script>

<style scoped lang="scss">
.header {
  position: sticky;
  width: calc(100vw - 32px);
  top: 0;
  z-index: 20;
  padding: 0 16px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;

  &-logo {
    flex: 1;
    display: flex;
    align-items: center;

    img {
      height: 37px;
      margin-right: 8px;
    }
  }

  .el-button {
    border: none;
  }

  .el-dropdown-link {
    margin-right: 8px;
    cursor: pointer;
  }

  &-op {
    display: flex;
    align-items: center;
  }
}
</style>
